import ClipboardButton from "../../components/ClipboardButton.tsx";
import CodeBlock from "../../components/CodeBlock.tsx";
import CodeBlock_clear from "../../components/CodeBlock_clear.tsx";

interface CurlCommandGeneratorProps {
  selectedServer: { url: string; description: string } | null;
  selectedPath: string | null;
  selectedMethod: string | null;
  selectedParameters: { name: string; in: string; value: string }[];
  selectedResponses: any[];
  methods: Record<string, any>;
  isAuthenticated: boolean;
}

export const CurlCommandGenerator = ({
  selectedServer,
  selectedPath,
  selectedMethod,
  selectedParameters,
  selectedResponses,
  methods,
  isAuthenticated,  // Only the authentication status is passed
}: CurlCommandGeneratorProps) => {
  const generateCurlCommand = () => {
    if (!selectedServer) return "Missing information: Server not selected";
    if (!selectedMethod) return "Missing information: Method not selected";
    if (!selectedPath) return "Missing information: Endpoint not selected";

    // Step 1: Replace path parameters with actual values
    let updatedPath = selectedPath;
    selectedParameters.forEach((param) => {
      if (param.in === "path" && param.value) {
        updatedPath = updatedPath.replace(`{${param.name}}`, encodeURIComponent(param.value));
      }
    });

    // Step 2: Start building the curl command
    let curlCommand = `curl -X '${selectedMethod.toUpperCase()}' '${selectedServer.url}${updatedPath}`;

    // Step 3: Add query parameters if any
    const queryParams = selectedParameters
      .filter((param) => param.in === "query" && param.value) // Only query parameters
      .map((param) => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
      .join("&");

    if (queryParams) {
      curlCommand += `?${queryParams}`;
    }

    // Step 4: Close the URL with a single quote
    curlCommand += `'`;

  // Step 5: Add Authorization header based on authentication and method security
  if (methods[selectedMethod]?.security) {
    if (isAuthenticated) {
      curlCommand += ` \\\n  -H 'Authorization: Bearer {actual_access_token}'`;
    } else {
      curlCommand += ` \\\n  -H 'Authorization: Bearer {access_token}'`;
    }
  }

    return curlCommand.trim();
  };

  // Generate and display the curl command
  const curlCommand = generateCurlCommand();

  return (
    <>
    <CodeBlock_clear code={curlCommand}  />
    </>
  );
};
